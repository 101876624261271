import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuetify from 'vuetify'
/* REPLACEMENT FOR MOMENT JS */
/* DOCS: https://www.npmjs.com/package/vue-luxon */
import VueLuxon from 'vue-luxon'
/* INTERNATIONALIZATION */
/* https://kazupon.github.io/vue-i18n/started.html#html */
import VueI18n from 'vue-i18n'
import da from '@/translations/da.json'
import en from '@/translations/en.json'
import se from '@/translations/sv_SE.json'
import '@/assets/css/main.css'

import setupInterceptors from '@/api/setupInterceptors'

/* DOCS: https://www.npmjs.com/package/@criipto/auth-js */
import CriiptoAuth from '@criipto/auth-js'

Vue.config.productionTip = false
Vue.use(Vuetify)
if(process.env.VUE_APP_I18N_LOCALE === 'da'){
    Vue.use(VueLuxon, {
        templates: {
            formatutc: {
                zone: 'utc',
                format: 'iso'         
            },
            formatlocal: {
                format: 'dd-MM-yyyy HH:mm:ss',
                zone: 'Europe/Copenhagen'            
            },
            formatlocaldate: {
                format: 'dd-MM-yyyy',
                zone: 'Europe/Copenhagen'            
            }
        },
        input: {
            format: 'iso',
            zone: 'utc'        
        },
        output: { 
            format: 'dd-MM-yyyy HH:mm:ss', 
            zone: 'Europe/Copenhagen'
        }
    })
} else if(process.env.VUE_APP_I18N_LOCALE === 'se'){
    Vue.use(VueLuxon, {
        templates: {
            formatutc: {
                zone: 'utc',
                format: 'iso'         
            },
            formatlocal: {
                format: 'yyyy-MM-dd HH:mm:ss',
                zone: 'Europe/Copenhagen'            
            },
            formatlocaldate: {
                format: 'yyyy-MM-dd',
                zone: 'Europe/Copenhagen'            
            }
        },
        input: {
            format: 'iso',
            zone: 'utc'        
        },
        output: { 
            format: 'yyyy-MM-dd HH:mm:ss', 
            zone: 'Europe/Copenhagen'
        }
    })
}
Vue.use(VueI18n)
let formatter = new Intl.NumberFormat()
if(process.env.VUE_APP_I18N_LOCALE === 'da'){
    formatter = new Intl.NumberFormat('da-DK', {
        style: 'currency',
        currency: 'DKK',
    })
} else if(process.env.VUE_APP_I18N_LOCALE === 'se'){
    formatter = new Intl.NumberFormat('sv-SE', {
        style: 'currency',
        currency: 'SEK',
    })
}

Vue.mixin({
    methods: {
        /*******************************************************************
         * GLOBAL FIELD VALIDATORS
         *******************************************************************/
        globalUsernameValidator: function(value) {
            let errors = []

            errors = errors.concat(this.globalCheckNotEmpty(value))

            errors = errors.concat(this.globalEmailValidator(value))

            return errors
        },
        globalCheckNotEmpty: function(value) {
            let errors = []
            if(value === null || !value.toString()){
                errors.push(this.$t('error-empty'))
            }
            return errors
        },
        globalRequireDigit: function(value) {   
            let errors = []         
            let digitRegex = /\d/
            if (digitRegex.test(value) === false) {
                errors.push(this.$t('error-missingdigit'))
            }
            return errors
        },
        globalRequireSpecialChar: function(value) {   
            let errors = []         
            let specialCharRegex = /[!@#$%^&]/
            if (specialCharRegex.test(value) === false) {
                errors.push(this.$t('error-missingspecialchar'))
            }
            return errors
        },
        globalCheckIfOnlyLettersAndDigits: function(value) {   
            let errors = []         
            let specialCharRegex = /[^A-Za-z0-9]+/
            if (specialCharRegex.test(value) === true) {
                errors.push(this.$t('error-specialcharincluded'))
            }
            return errors
        },
        globalRequiredLowerCase: function(value) {
            let errors = []   
            let hasOnlyUppercases = value.toUpperCase() === value
            if (hasOnlyUppercases) {
                errors.push(this.$t('error-missinglowercase'))
            }
            return errors
        },
        globalRequiredUpperCase: function(value) {
            let errors = []   
            let hasOnlyLowercases = value.toLowerCase() === value
            if (hasOnlyLowercases) {
                errors.push(this.$t('error-missinguppercase'))
            }
            return errors
        },
        globalPasswordValidator: function(value){
            let errors = []

            errors = errors.concat(this.globalCheckNotEmpty(value))

            if(value){
                errors = errors.concat(this.globalRequireDigit(value))

                //errors = errors.concat(this.globalRequireSpecialChar(value))
    
                errors = errors.concat(this.globalRequiredLowerCase(value))
    
                errors = errors.concat(this.globalRequiredUpperCase(value))
    
                // Minimum 8 characters
                if (value.length < 8) {
                    errors.push(this.$t('error-password-mincharacter'))
                }
            }            

            return errors
        },
        globalConfirmPasswordValidator: function(value, value2){
            let errors = []

            errors = errors.concat(this.globalCheckNotEmpty(value))

            if(value){
                errors = errors.concat(this.globalRequireDigit(value))

                //errors = errors.concat(this.globalRequireSpecialChar(value))
    
                errors = errors.concat(this.globalRequiredLowerCase(value))
    
                errors = errors.concat(this.globalRequiredUpperCase(value))
    
                // Minimum 8 characters
                if (value.length < 8) {
                    errors.push(this.$t('error-password-mincharacter'))
                }

                if(value !== value2){
                    errors.push(this.$t('error-password-mismatch'))
                }
            }            

            return errors
        },
        globalCheckIsNumber: function(value){
            let errors = []

            errors = errors.concat(this.globalCheckNotEmpty(value))

            if(value){
                if(isNaN(value)){
                    errors.push(this.$t('error-notanumber'))
                }
            }            

            return errors
        },
        globalEmailValidator: function(value) {
            let errors = []

            errors = errors.concat(this.globalCheckNotEmpty(value))

            if (value) {
                let emailRegex = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                if (emailRegex.test(value) === false) {
                    errors.push(this.$t('error-emailwrongformat'))
                }
            }
            
            return errors
        },
        globalPhoneNumberValidator: function(value){
            let errors = []

            errors = errors.concat(this.globalCheckNotEmpty(value))

            if (value) {
                let phonenumberRegex = /^\d+$/
                if (phonenumberRegex.test(value) === false) {
                    errors.push(this.$t('error-phonenumber-wrongformat'))
                }
                // Minimum 8 characters
                if (value.length < 8) {
                    errors.push(this.$t('error-phonenumber-minlenght'))
                }
                if(value.length > 8){
                    errors.push(this.$t('error-phonenumber-maxlenght'))
                }
            }
            return errors
        },
        globalCvrNumberValidator: function(value) {
            let errors = []

            errors = errors.concat(this.globalCheckNotEmpty(value))
            errors = errors.concat(this.globalCheckIsNumber(value))

            // Minimum 8 characters
            if(value){
                if (value.length < 8) {
                    errors.push(this.$t('error-cvr-minlenght'))
                }
                if (value.length > 8){
                    errors.push(this.$t('error-cvr-maxlenght'))
                }
            }
            

            return errors
        },
        globalPNumberValidator: function(value) {
            let errors = []

            errors = errors.concat(this.globalCheckNotEmpty(value))
            errors = errors.concat(this.globalCheckIsNumber(value))

            return errors
        },
        globalRegistrationNumberValidator: function(value) {
            let errors = []

            errors = errors.concat(this.globalCheckNotEmpty(value))
            //errors = errors.concat(this.globalCheckIfOnlyLettersAndDigits(value))

            

            if(value && value.length < 5 && process.env.VUE_APP_I18N_LOCALE === 'da'){
                errors.push(this.$t('error-registrationnumber-too-short'))
            } else if(value && value.length < 6 && process.env.VUE_APP_I18N_LOCALE === 'se'){
                errors.push(this.$t('error-registrationnumber-too-short'))
            }

            return errors
        },
        globalEan13Validator: function(value) {
            let errors = []

            let gtinRegex = new RegExp('^(\\d{8}|\\d{12,14})$')

            if (value.length !== 13) {
                errors.push(this.$t('error-ean13-length'))
            } else if (!(gtinRegex.test(value))) {
                errors.push(this.$t('error-invalid-ean13'))
            } else {
                value = value.padStart(14, '0')
                let sum = 0

                value.split('').map(function(number, index) {
                    number = parseInt(number, 10)
                    if (value.length % 2 === 0) {
                        index += 1
                    }
                    if (index % 2 === 0) {
                        sum += number
                    } else {
                        sum += number * 3
                    }
                })

                // let sum = value.Select((c, i) => (c - '0') * ((i % 2 == 0) ? 3 : 1)).Sum()

                if ((sum % 10) !== 0) {
                    errors.push(this.$t('error-invalid-ean13'))
                }
            }

            return errors
        },
        /*******************************************************************
         * GLOBAL CURRENCY CONVERTERS
         *******************************************************************/
        globalConvertMinorCurrencyToLocal: function(value) {
            return formatter.format(value/100)
        }
    },
})

setupInterceptors(store)

if(process.env.VUE_APP_USE_CRIIPTO_LOGIN === 'true'){
    let criiptoAuth = new CriiptoAuth({
        domain: process.env.VUE_APP_CRIIPTO_DOMAIN, //'optidata-a-test.criipto.id',
        clientID: process.env.VUE_APP_CRIIPTO_CLIENT_ID, //'urn:kundeportal:optidata',
        store: sessionStorage
    })
    
    Vue.prototype.$criipto = criiptoAuth

    if(process.env.VUE_APP_CRIIPTO_ALLOW_PRIVATE_LOGIN === 'true'){
        let criiptoPrivateAuth = new CriiptoAuth({
            domain: process.env.VUE_APP_CRIIPTO_DOMAIN, //'optidata-a-test.criipto.id',
            clientID: process.env.VUE_APP_CRIIPTO_CLIENT_ID_PRIVATE, //'urn:kundeportal:optidata',
            store: sessionStorage,
            scope: process.env.VUE_APP_CRIIPTO_SCOPE
        })

        Vue.prototype.$criiptoPrivate = criiptoPrivateAuth
    }
}



new Vue({
    router,
    store,
    vuetify: new Vuetify(),
    i18n: new VueI18n({
        locale: process.env.VUE_APP_I18N_LOCALE, // set locale
        fallbackLocale: 'da', // set fallback locale
        messages: {
            en: en,
            da: da,
            se: se
        } // set locale messages
    }),
    render: h => h(App),
}).$mount('#app')


